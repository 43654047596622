import { ICoreContext } from '@msdyn365-commerce/core';
import { getAttributeStringOrDefault, getAttributesLocale } from './attributes';
import { getMostUsedAttributeNameIndex, sortAttributes, sortAttributesByGroupOrder } from '.';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';

export interface IAttributeGroupMapAttribute {
    name: string | string[];
    label?: string;
    hideIfEmpty?: boolean;
    transform?: (value: string) => string;
    transformName?: (key: string) => string;
}

export interface IAttributeGroupMap {
    type: AttributeGroupType;
    group: string;
    attributes?: (string | IAttributeGroupMapAttribute)[];
    transform?: (value: string) => string;
    defaultValue?: string;
    nullValues?: string[];
    /** The attribute to use as source. If set this will look for the attributes inside this attribute. */
    attributeSource?: string;
    attributeSourceSet?: { source: string, value: string }[];
    exclude?: string[];
}

export type AttributeGroupType = 'default' | 'allergens';
export type ModelVersion = '1.0' | '2.0' | '3.0';

export interface IParsedAttributeMap {
    type: AttributeGroupType;
    name: string;
    attributes: IParsedAttribute[];
    modelVersion?: ModelVersion;
}

export interface IParsedAttribute {
    name: string;
    value: string;
}

export const replaceCommaWithDot = (value: string): string => {
    // Shouldn't need to replace anything if both are present
    if (value.includes(',') && value.includes('.')) {
        return value.replace(' ', '');
    }

    // Only replace the last occurance of a comma with a dot
    const lastCommaIndex = value.lastIndexOf(',');
    if (lastCommaIndex !== -1) {
        return `${value.substring(0, lastCommaIndex)}.${value.substring(lastCommaIndex + 1)}`.replace(' ', '');
    }

    // If no comma is present, return the value as is
    return value.replace(' ', '');

    // return value.replace(',', '.').replace(' ', '');
};
export const padValue = (value: string): string => {
    return Number(value.replace(',', '')).toFixed(2).toString();
};
export const addCelsiusSign = (value: string): string => {
    return value ? `${value}°` : value;
};
export const addDashPrefix = (key: string): string => {
    return ` - ${key}`;
};
export const addLeftPaddingHTML = (value: string): string => {
    return `\t${value}`;
};

function findAttribute(attributeName: string): (attribute: string | IAttributeGroupMapAttribute) => boolean {
    return (attribute: string | IAttributeGroupMapAttribute) => {
        if (typeof attribute === 'string') {
            return attribute === attributeName;
        } else {
            return attribute.name === attributeName;
        }
    };
}

export function parseAttributesMap(context: ICoreContext, attributes: AttributeValue[] | undefined, attributesMap?: IAttributeGroupMap[]): IParsedAttributeMap[] {
    if (!attributesMap) {
        attributesMap = getAttributesMap(context);
    }

    const attributesLocale = getAttributesLocale(context.request.locale);
    const groups: {
        [key: string]: AttributeValue[];
    } = {};

    attributes!.sort(sortAttributesByGroupOrder).map((product: AttributeValue, index) => {
        const attrFind = product.ExtensionProperties?.find((e: any) => {
            return e.Key === 'ATTRGROUPNAME';
        });
        if(attrFind && attrFind.Value && attrFind.Value.StringValue){
            const key = attrFind.Value.StringValue.trim();
            if (groups[key]) {
                groups[key].push(product);
            } else {
                groups[key] = [];
                groups[key].push(product);
            }
        }
    });

    const parsed: IParsedAttributeMap[] = attributesMap.map((attributeGroup) => {
        const mostCommonNameIndex = getMostUsedAttributeNameIndex(attributes, attributeGroup, attributesLocale.notFound);

        if (!attributeGroup.attributes && !groups[attributeGroup.group]) {
            return {
                type: attributeGroup.type,
                name: attributeGroup.group,
                attributes: []
            };
        }

        let modelVersion: ModelVersion = '1.0';
        let attributesToUse: AttributeValue[] | undefined;
        const attributeSource = attributeGroup.attributeSource
            ? attributes!.find((attribute: AttributeValue) => attribute.Name === attributeGroup.attributeSource)
            : undefined;
        const shouldUseAttributeSet = attributeGroup.attributeSourceSet?.some((source) => {
            return attributes?.find((attribute: AttributeValue) => attribute.Name === source.source)?.TextValue;
        });

        if (shouldUseAttributeSet) {
            modelVersion = '3.0';
            attributesToUse = [];

            attributeGroup.attributeSourceSet!.forEach((source) => {
                const attribute = attributes?.find((attribute: AttributeValue) => attribute.Name === source.source);

                if (!attribute || !attribute.TextValue) {
                    return;
                }

                attributesToUse!.push(...attribute.TextValue.split('|').map((attribute) => {
                    return {
                        Name: attribute,
                        DataTypeValue: 5,
                        TextValue: source.value,
                        ExtensionProperties: [
                            {
                                Key: 'ATTRORDER',
                                Value: {
                                    DecimalValue: (attributeGroup.attributes?.findIndex((attr) => {
                                        if (typeof attr === 'string') {
                                            return attr === attribute;
                                        } else {
                                            return attr.name === attribute;
                                        }
                                    }) ?? -1) + 1
                                }
                            }
                        ]
                    } as AttributeValue;
                }));
            });
        } else if (attributeGroup.attributeSource && attributeSource?.TextValue) {
            modelVersion = '2.0';
            attributesToUse = attributeSource?.TextValue?.split('|').map((attribute) => {
                return {
                    Name: attribute.split(':')[0],
                    DataTypeValue: 5,
                    TextValue: attribute.split(':')[1]
                } as AttributeValue;
            });
        } else {
            attributesToUse = attributes;
        }

        const attrs = !shouldUseAttributeSet && attributeGroup.attributes
            ? attributeGroup.attributes.map((attribute: IAttributeGroupMapAttribute | string) => {
                if (typeof attribute === 'string') {
                    let value = getAttributeStringOrDefault(attributesToUse, attribute, attributesLocale.notFound);

                    if (attributeGroup.transform) {
                        value = attributeGroup.transform(value);
                    }

                    return {
                        name: attribute,
                        value
                    };
                } else {
                    let name: string;
                    let value: string | undefined;

                    if (typeof attribute.name === 'string') {
                        name = attribute.label || attribute.name;
                        value = getAttributeStringOrDefault(attributesToUse,
                            attribute.name,
                            attributeGroup.defaultValue);
                    } else {
                        const values: {
                            [key: string]: string | undefined
                        } = {};

                        attribute.name.forEach((name: string) => {
                            values[name] = getAttributeStringOrDefault(attributesToUse, name, attributeGroup.defaultValue);
                        });

                        if (Object.values(values).every((v) => !v || attributeGroup.nullValues?.includes(v))) {
                            name = attribute.name[mostCommonNameIndex];
                            value = attributeGroup.defaultValue;
                        } else {
                            const found = Object.entries(values).find(([key, val]) => val && !attributeGroup.nullValues?.includes(val));

                            if (!found) {
                                name = attribute.label || attribute.name[mostCommonNameIndex];
                                value = attributeGroup.defaultValue;
                            } else {
                                name = attribute.label || found[0];
                                value = found[1];
                            }
                        }
                    }

                    if (attribute.transformName) {
                        name = attribute.transformName(name);
                    }

                    if (value && attribute.transform) {
                        value = attribute.transform(value);
                    } else if (value && attributeGroup.transform) {
                        value = attributeGroup.transform(value);
                    }

                    if (attribute.hideIfEmpty === true && value === undefined) {
                        return undefined;
                    }

                    return {
                        name,
                        value: value || attributesLocale.notFound
                    };
                }
            })
            : ((shouldUseAttributeSet || attributeGroup.attributeSource && attributeSource?.TextValue) ? attributesToUse! : groups[attributeGroup.group]).sort(sortAttributes).map((attribute: AttributeValue) => {
                if (!attribute.Name) {
                    throw new Error(`Attribute name is missing.`);
                }

                if (attributeGroup.exclude && attributeGroup.exclude.includes(attribute.Name)) {
                    return undefined;
                }

                let name = attribute.Name;
                let value = getAttributeStringOrDefault(attributesToUse,
                    attribute.Name,
                    attributeGroup.defaultValue || attributesLocale.notFound);

                if (attributeGroup.transform) {
                    value = attributeGroup.transform(value);
                }

                const findAttributeFromAttributes = attributeGroup.attributes?.find(findAttribute(attribute.Name));

                if (typeof findAttributeFromAttributes === 'object') {
                    if (findAttributeFromAttributes.transformName) {
                        name = findAttributeFromAttributes.transformName(attribute.Name);
                    }
                }

                return {
                    name,
                    value
                };
            });

        return {
            type: attributeGroup.type,
            name: attributeGroup.group,
            attributes: attrs.filter(x => x).map((attribute) => {
                const { name, value } = attribute || {};

                return {
                    name: name as string,
                    value: value as string
                };
            }),
            modelVersion
        };
    });

    return parsed;
}

export function getAttributesMap(context: ICoreContext): IAttributeGroupMap[] {
    const attributesLocale = getAttributesLocale(context.request.locale);

    return [
        {
            type: 'default',
            group: attributesLocale.groups.nutrients,
            transform: (value) => {
                value = replaceCommaWithDot(value);
                value = padValue(value);
                return value;
            },
            defaultValue: '0.00',
            nullValues: ['0', '0.00', attributesLocale.notFound],
            attributeSource: attributesLocale.groups.nutrients,
            attributes: [
                {
                    name: [
                        attributesLocale.energyKjPer100g,
                        attributesLocale.energyKjPer100ml,
                        attributesLocale.energyKjPer100gml
                    ]
                },
                {
                    name: [
                        attributesLocale.energyKcalPer100g,
                        attributesLocale.energyKcalPer100ml,
                        attributesLocale.energyKcalPer100gml
                    ]
                },
                {
                    name: [
                        attributesLocale.fatPer100g,
                        attributesLocale.fatPer100ml,
                        attributesLocale.fatPer100gml
                    ]
                },
                {
                    name: [
                        attributesLocale.saturatedFattyAcidsPer100g,
                        attributesLocale.saturatedFattyAcidsPer100ml,
                        attributesLocale.saturatedFattyAcidsPer100gml
                    ],
                    transformName: addDashPrefix
                },
                {
                    name: [
                        attributesLocale.monounsaturatedFattyAcidsPer100g,
                        attributesLocale.monounsaturatedFattyAcidsPer100ml,
                        attributesLocale.monounsaturatedFattyAcidsPer100gml
                    ],
                    transformName: addDashPrefix
                },
                {
                    name: [
                        attributesLocale.polyunsaturatedFattyAcidsPer100g,
                        attributesLocale.polyunsaturatedFattyAcidsPer100ml,
                        attributesLocale.polyunsaturatedFattyAcidsPer100gml
                    ],
                    transformName: addDashPrefix
                },
                {
                    name: [
                        attributesLocale.carbohydratesPer100g,
                        attributesLocale.carbohydratesPer100ml,
                        attributesLocale.carbohydratesPer100gml
                    ]
                },
                {
                    name: [
                        attributesLocale.sugarsPer100g,
                        attributesLocale.sugarsPer100ml,
                        attributesLocale.sugarsPer100gml
                    ],
                    transformName: addDashPrefix
                },
                {
                    name: [
                        attributesLocale.dietaryFibersPer100g,
                        attributesLocale.dietaryFibersPer100ml,
                        attributesLocale.dietaryFibersPer100gml
                    ]
                },
                {
                    name: [
                        attributesLocale.proteinsPer100g,
                        attributesLocale.proteinsPer100ml,
                        attributesLocale.proteinsPer100gml
                    ]
                },
                {
                    name: [
                        attributesLocale.saltPer100g,
                        attributesLocale.saltPer100ml,
                        attributesLocale.saltPer100gml
                    ]
                }
            ]
        },
        {
            type: 'allergens',
            group: attributesLocale.groups.allergens,
            attributeSource: attributesLocale.groups.allergens,
            attributeSourceSet: [
                {
                    source: attributesLocale.groups.allergensContains,
                    value: attributesLocale.labels.yes
                },
                {
                    source: attributesLocale.groups.allergensCanContainTraces,
                    value: attributesLocale.labels.canContains
                },
                {
                    source: attributesLocale.groups.allergensDoesNotContain,
                    value: attributesLocale.labels.freeFrom
                }
            ],
            attributes: [
                attributesLocale.allergens.molluscs,
                attributesLocale.allergens.eggs,
                attributesLocale.allergens.fish,
                attributesLocale.allergens.gluten,
                {
                    name: attributesLocale.allergens.barleyGluten,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.oatGluten,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.wheatGluten,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.khorasanWheatGluten,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.ryeGluten,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.speltGluten,
                    transformName: addLeftPaddingHTML
                },
                attributesLocale.allergens.lupin,
                attributesLocale.allergens.milk,
                attributesLocale.allergens.nuts,
                {
                    name: attributesLocale.allergens.hazelnuts,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.cashews,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.macadamiaNuts,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.almonds,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.brazilNuts,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.peanuts,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.pecans,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.pistachios,
                    transformName: addLeftPaddingHTML
                },
                {
                    name: attributesLocale.allergens.walnuts,
                    transformName: addLeftPaddingHTML
                },
                attributesLocale.allergens.celery,
                attributesLocale.allergens.mustard,
                attributesLocale.allergens.sesameSeeds,
                attributesLocale.allergens.shellfish,
                attributesLocale.allergens.soy,
                attributesLocale.allergens.sulphurDioxideOrSulfites
            ],
            exclude: [
                attributesLocale.groups.allergens,
                attributesLocale.groups.allergensContains,
                attributesLocale.groups.allergensCanContainTraces,
                attributesLocale.groups.allergensDoesNotContain
            ],
            transform: (value: string) => {
                switch (value) {
                    case attributesLocale.values.contains:
                        return attributesLocale.labels.yes;
                    case attributesLocale.values.containsNot:
                        return attributesLocale.labels.freeFrom;
                    case attributesLocale.values.canContainTraces:
                        return attributesLocale.labels.canContains;
                    default:
                        return value;
                }
            }
        },
        {
            type: 'default',
            group: attributesLocale.groups.productInformation,
            attributes: [
                {
                    name: attributesLocale.temperatureMin,
                    transform: addCelsiusSign
                },
                {
                    name: attributesLocale.temperatureMax,
                    transform: addCelsiusSign
                },
                attributesLocale.gtin,
                attributesLocale.countryOfOrigin,
                attributesLocale.quantity
            ]
        }
    ];
}
